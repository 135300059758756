import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../../assets/css/footer.css"
import Apple from '../../assets/images/apple.png'
import Google from '../../assets/images/google.png'

export class FooterD extends Component {
  render() {
    return (
        <Fragment>
          <div className=' footerDesktop'>
            <Container fluid={true} className='p-0 m-0 oveflow-hidden card' >
                <Row className=' color-black'>
                   <Col  lg={3} md={4} sm={6} xs={12} className='padding-15'>
                      <h5 className='footer-menu-title'>Contacteaza-ne</h5>
                      <div className="footer-contacts__text">
                                     SC. Dipieso Junior, companie 100% romaneasca - infiintata in anul 1991 - obiect de activitate comercializarea de piese schimb electrocasnice.
                      </div>
                      <ul className="footer-contacts__contacts">
                                    <li><i className="footer-contacts__icon fas fa-globe-americas" aria-hidden="true"></i> Str. Valeriu Braniste, Nr.16, Bucuresti, 30716 </li>
                                    <li><i className="footer-contacts__icon far fa-envelope" aria-hidden="true"></i><a href="mailto:office@pieseelectro.ro"> office@pieseuzcasnic.ro </a></li>
                                    <li><i className="footer-contacts__icon fas fa-mobile-alt" aria-hidden="true"></i><a href="tel:0749550628" title="Suna: 0749 550 628">0749 550 628</a></li>
                                    <li><i className="footer-contacts__icon far fa-clock" aria-hidden="true"></i> Luni-Vineri 10:00am - 18:00pm</li>
                      </ul>
                   </Col>

                   <Col  lg={3} md={4} sm={6} xs={12} className='padding-15 '>
                   <div className="site-footer__widget footer-links">
                                <h4 className="footer-menu-title">Informatii</h4>
                                <ul className="footer-links__list">
                                    <li className="footer-links__item"><a href="contact.php" className="footer-links__link">Despre noi</a></li>
                                    <li className="footer-links__item"><a href="contact.php" className="footer-links__link">Contact</a></li>
                                    <li className="footer-links__item"><a href="termeni-conditii.php" className="footer-links__link">Confidentialitate</a></li>
                                    <li className="footer-links__item"><a href="termeni-conditii.php" className="footer-links__link">Plata</a></li>
                                    <li className="footer-links__item"><a href="termeni-conditii.php" className="footer-links__link">Livrare</a></li>
                                    <li className="footer-links__item"><a href="termeni-conditii.php" className="footer-links__link">Retur</a></li>
                                    <li className="footer-links__item"><a href="termeni-conditii.php" className="footer-links__link">Sugestii si reclamatii</a></li>
                                </ul>
                            </div>
                   </Col>

                   <Col  lg={3} md={4} sm={6} xs={12} className='padding-15 '>
                   <div className="site-footer__widget footer-links">
                                <h4 className="footer-menu-title">Utile</h4>
                                <ul className="footer-links__list">
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Contul meu</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Istoric Comenzi</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Profil</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Categorii</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Marci</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Produse</a></li>
                                    <li className="footer-links__item"><a href="https://PieseElectro.ro" className="footer-links__link">Promotii</a></li>
                                </ul>
                            </div>
                   </Col>

                   <Col  lg={3} md={4} sm={6} xs={12} className='padding-15 '>
                   <div className="site-footer__widget footer-newsletter">
                                <h5 className="footer-menu-title">DOWNLOAD APPS</h5>
                                  <a><img src={Google}  /></a><br></br>
                                  <a><img className="mt-2" src={Apple}  /></a><br></br>
                            </div>
                   </Col>

                </Row>
            </Container>
            </div>
        </Fragment>
    )
  }
}

export default FooterD
