import React from 'react';
import { useMediaQuery } from 'react-responsive';
import FooterD from './FooterD';
import FooterM from './FooterM';

const Footer = () => {
  // Use media query to detect if it's a desktop-sized screen or not
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <>
      {isDesktop ? (
        <div className="desktop-menu-wrapper footer">
          <FooterD />
        </div>
      ) : (
        <div className="mobile-menu-wrapper">
          <FooterM />
        </div>
      )}
    </>
  );
};

export default Footer;
