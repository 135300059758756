import React, { Component, Fragment } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../assets/css/navMenuDesktop.css";
import Logo from "../../assets/images/logo.png";
import { FaChevronRight } from 'react-icons/fa';
import Placeholder from 'react-bootstrap/Placeholder';

export class NavMenuD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      searchResults: [],
      showResults: false,
      dummyData: [
        { id: 1, name: 'Electronics' },
        { id: 2, name: 'Fashion' },
        { id: 3, name: 'Home Appliances' },
        { id: 4, name: 'Books' },
        { id: 5, name: 'Toys' }
      ]
    };
  }

  handleSearchInput = (event) => {
    const query = event.target.value;
    this.setState({ searchQuery: query, showResults: query.length > 0 }, () => {
      if (query.length > 0) {
        // Simulate an AJAX search by filtering dummy data
        const filteredResults = this.state.dummyData.filter(item =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );
        this.setState({ searchResults: filteredResults });
      } else {
        this.setState({ searchResults: [] });
      }
    });
  };

  render() {
    const { searchResults, showResults, searchQuery } = this.state;

    return (
      <Fragment>
        <div className="fixed-nav-wrapper">
          <div className="fixed-nav-content">
            {/* Logo */}
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>

            {/* Search */}
            <div className="search-container">
              <Form className="d-flex mb-4">
                <FormControl
                  type="search"
                  placeholder="Introduceți termenii de căutare"
                  className="search-input"
                  aria-label="Caută"
                  value={searchQuery}
                  onChange={this.handleSearchInput}
                />
                <Button variant="outline-success" className="search-btn">
                  <i className="fa fa-search"></i>
                </Button>
              </Form>
              {showResults && (
                <div className="search-results">
                  {searchResults.length > 0 ? (
                    searchResults.map((result) => (
                      <div key={result.id} className="menu-category-card">
                        <Placeholder as="div" animation="wave" className="menu-category-image-placeholder" />
                        <span className="menu-category-name">{result.name}</span>
                        <FaChevronRight className="menu-category-icon" />
                      </div>
                    ))
                  ) : (
                    <div className="no-results">No results found</div>
                  )}
                </div>
              )}
            </div>

            {/* Additional Buttons */}
            <div className="action-buttons">
              <Link to="/" className="btn">
                <i className="fa h4 fa-bell"></i>
              </Link>
              <Button className="cart-btn">
                <i className="fa fa-shopping-cart"></i> 3 Items
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NavMenuD;
