
import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/footerBarMobile.css";

const FooterM = () => {
  return (
    <div className="footer-bar">
      <Link to="/" className="footer-bar-item home">
        <i className="fa fa-home"></i>
        <span>Home</span>
      </Link>
      <Link to="/categories" className="footer-bar-item categories">
        <i className="fa fa-th-large"></i>
        <span>Categories</span>
      </Link>
      <Link to="/cart" className="footer-bar-item cart">
        <i className="fa fa-shopping-cart"></i>
        <span>Cart</span>
      </Link>
      <Link to="/profile" className="footer-bar-item profile">
        <i className="fa fa-user"></i>
        <span>Profile</span>
      </Link>
      <Link to="/login" className="footer-bar-item settings">
        <i className="fa fa-cog"></i>
        <span>Settings</span>
      </Link>
    </div>
  );
};

export default FooterM;
