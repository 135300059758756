import React from 'react';
import NavMenuD from './NavMenuD';
import NavMenuM from './NavMenuM';
import { useMediaQuery } from 'react-responsive';

const NavMenu = () => {
  // Use media query to detect if it's a desktop-sized screen or not
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <>
      {isDesktop ? (
        <div className="desktop-menu-wrapper">
          <NavMenuD />
        </div>
      ) : (
        <div className="mobile-menu-wrapper">
          <NavMenuM />
        </div>
      )}
    </>
  );
};

export default NavMenu;
