import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Button, Form, FormControl, Card, Nav, Placeholder } from 'react-bootstrap';
import { FaBars, FaSearch, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import '../../assets/css/navMenuMobile.css';

const NavMenuM = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleMenu = () => setShowMenu(!showMenu);
  const toggleSearch = () => setShowSearch(!showSearch);

  const menuCategories = [
    { name: "Electrocasnice mari" },
    { name: "Electrocasnice mici" },
    { name: "Electronice" },
    { name: "Încălzire și climatizare" },
    { name: "Piese de schimb" },
    { name: "Promoții" },
  ];

  const searchResults = [
    "Frigidere și congelatoare",
    "Mașini de spălat rufe",
    "Mașini de spălat vase",
    "Cuptoare electrice",
    "Aspiratoare",
    "Televizoare și media",
  ];

  return (
    <>
      <Navbar fixed="top" bg="light" className="shadow-sm p-2 mb-0">
        <Container className="d-flex justify-content-between align-items-center">
          <Button variant="link" className="p-0" onClick={toggleMenu}>
            <FaBars size={24} />
          </Button>
          <Navbar.Brand as={Link} to="/" className="mx-auto">
            <img src={Logo} alt="Logo" height="30" />
          </Navbar.Brand>
          <Button variant="link" className="p-0" onClick={toggleSearch}>
            <FaSearch size={24} />
          </Button>
        </Container>
      </Navbar>

      {/* Offcanvas Menu */}
      <Offcanvas show={showMenu} onHide={toggleMenu} placement="start" className="menu-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Categorii</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {menuCategories.map((category, index) => (
              <div key={index} className="menu-category-card">
                <Placeholder as="div" animation="wave" className="menu-category-image-placeholder" />
                <span className="menu-category-name">{category.name}</span>
                <FaChevronRight className="menu-category-icon" />
              </div>
            ))}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Offcanvas Search */}
      <Offcanvas show={showSearch} onHide={toggleSearch} placement="end" className="search-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Caută:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="d-flex mb-4">
            <FormControl
              type="search"
              placeholder="Introduceți termenii de căutare"
              className="search-input"
              aria-label="Caută"
            />
            <Button variant="outline-success" className="search-btn">
              <i className="fa fa-search"></i>
            </Button>
          </Form>
          <Card className="mb-3">
            <Card.Body>
              <h6 className="mb-3">Căutări populare:</h6>
              <div className="search-tags">
                <Button variant="outline-primary" className="me-2">Electrocasnice</Button>
                <Button variant="outline-primary" className="me-2">Piese de schimb</Button>
                <Button variant="outline-primary">Promoții</Button>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <h6 className="mb-3">Rezultate:</h6>
              {searchResults.map((result, index) => (
                <div key={index} className="search-result-item">
                  {result}
                  {index < searchResults.length - 1 && <hr />}
                </div>
              ))}
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavMenuM;
